<template>
    <div class="videoTutorialList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">视频教程列表</div>
        </div>
        <div class="case-wrap">
            <!-- <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
                <el-tab-pane label="基础教程" name="first"></el-tab-pane>
                <el-tab-pane label="营销教程" name="second"></el-tab-pane>
            </el-tabs> -->
            <div class="add-video">
                <div class="select-wrap">
                    <!-- <el-select clearable v-model="videoForm.type" @change="changeType" placeholder="请根据视频类型筛选">
                        <el-option
                            v-for="item in ubOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
                    <div class="search">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <el-button type="primary" @click="isAddShow = true">创建视频</el-button>
            </div>
            <el-table :data="dataTable" border v-loading="loading">
                <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
                <!-- <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column> -->
                <el-table-column align="center" prop="subcategory" label="类型">
                    <template slot-scope="scope">
                        <div v-if="scope.row.subcategory == 1">常用功能教程</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="封面图">
                    <template slot-scope="scope">
                        <img :src="scope.row.cover_url" alt="" height="30">
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="" label="视频">
                    <template slot-scope="scope">
                        <video :src="scope.row.video_url" height="30"></video>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="title" label="标题"></el-table-column>
                <el-table-column align="center" prop="content" label="描述" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="views" label="播放量"></el-table-column>
                <el-table-column align="center" prop="status" label="状态">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 1">启用</div>
                        <div v-if="scope.row.status == 0">禁用</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="sort" label="排序"></el-table-column>
                <el-table-column align="center" prop="create_timex" label="创建时间" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :before-close="handleClose" 
            :close-on-click-modal="false"
            :visible.sync="isAddShow" 
            custom-class="dialog-wrap">
            <div class="title">添加教程视频</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="videoForm" :rules="rulesVideo" ref="videoForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="视频分类" prop="type" required>
                        <el-select v-model="videoForm.type">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="视频类型" prop="subcategory">
                        <el-select v-model="videoForm.subcategory">
                            <el-option
                                v-for="item in ubOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="视频标题" prop="title">
                        <el-input v-model="videoForm.title" placeholder="请输入视频标题"></el-input>
                    </el-form-item>
                    <el-form-item label="视频描述" prop="describe">
                        <el-input v-model="videoForm.content" placeholder="请输入视频描述"></el-input>
                    </el-form-item>
                    <el-form-item label="视频状态" prop="status" required>
                        <el-select v-model="videoForm.status">
                            <el-option
                                v-for="item in stateOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input-number v-model="videoForm.sort" :min="1" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="封面图片" prop="cover_url">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false"
                                :btnDisplay="cover.length<1" listType="picture-card"
                                v-model="cover">
                            </aliyun-upload>
                            <div v-if="cover.length>0" class="fileList-box">
                                <div @click="delImageList(1)" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="cover" :src="cover[0]"/>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="教程视频" prop="video_url">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="300" :limit="1" :isShopTip="false" accept="video/*" :isVideoType="true"
                                :btnDisplay="video.length<1" listType="picture-card"
                                v-model="video" @videoInfo="videoInfo">
                            </aliyun-upload>
                            <div v-if="video.length>0" class="fileList-box">
                                <div @click="delImageList(2)" class="delFile">x</div>
                                <video controls :src="video[0]" class="pic"></video>
                            </div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="addLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="addLoading" type="primary" @click="editForm">编辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'videoTutorialList',
            dataTable: [],
            currentPage: 1,
            total: 0,
            keyword: '',
            loading: false,
            stateOptions: [{
                label: '启用',
                value: 1
            },{
                label: '禁用',
                value: 0
            }],
            typeOptions: [{
                label: '基础教程',
                value: 1
            },
            // {
            //     label: '营销教程',
            //     value: 2
            // }
            ],
            ubOptions: [{
                value: 1,
                label: '常用功能教程'
            }],
            isAddShow: false,
            addLoading: false,
            editId: -1,
            cover: [],
            video: [],
            videoForm: {
                type: 1,
                subcategory: 1,
                title: '',
                content: '',
                sort: '',
                status: 1,
                cover_url: '',
                video_url: ''
            },
            rulesVideo: {
                title: [
                    { required: true, message: '请输入视频标题', trigger: 'blur' },
                ],
                subcategory: [
                    { required: true, message: '请选择视频类型', trigger: 'change' }
                ],
                cover_url: [
                    { required: true, message: '请上传视频封面', trigger: 'blur' },
                ],
                video_url: [
                    { required: true, message: '请上传视频内容', trigger: 'blur' },
                ],
            },
            activeName: 'first',
            type: 1
        }
    },
    watch:{
        cover(newVal,oldVal){
            this.videoForm.cover_url = newVal[0]
        },
        video(newVal,oldVal){
            this.videoForm.video_url = newVal[0]
        },
        isAddShow(newVal,oldVal){
            if(!newVal){
                let videoForm = {
                    type: 1,
                    subcategory: 1,
                    title: '',
                    content: '',
                    sort: '',
                    status: 1,
                    cover_url: '',
                    video_url: ''
                }
                this.videoForm = videoForm;
                this.video = [];
                this.cover = [];
                this.editId = -1;
            }
        }
    },
    mounted () {
        this.getVideoList();
    },
    methods: {
        tabClick(){
            if (this.activeName == 'first') {
                this.type = 1;
            }else{
                this.type = 2;
            }
            this.getVideoList();
        },
        // 上传视频时获取视频大小时长
        videoInfo(value){
            this.videoForm.duration = value.duration;
            this.videoForm.size = value.size;
        },
        // 创建
        submitForm(){
            this.$refs.videoForm.validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    common.connect('/customerservicev1/shop_tutorial_video/store',this.videoForm,(res)=>{
                        this.isAddShow = false;
                        this.addLoading = false;
                        this.$refs.videoForm.resetFields();
                        this.getVideoList();
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 确定编辑
        editForm(){
            this.$refs.videoForm.validate((valid) => {
                if (valid) {
                    this.addLoading = true;
                    this.videoForm.id = this.editId
                    common.connect('/customerservicev1/shop_tutorial_video/update',this.videoForm,(res)=>{
                        this.isAddShow = false;
                        this.addLoading = false;
                        this.getVideoList();
                        this.video = [];
                        this.cover = [];
                        this.editId = -1;
                        this.$refs.videoForm.resetFields();
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect('/customerservicev1/shop_tutorial_video/edit',{id: row.id},(res)=>{
                this.isAddShow = true;
                this.videoForm.type = res.data.type;
                this.videoForm.subcategory = res.data.subcategory;
                this.videoForm.title = res.data.title;
                this.videoForm.content = res.data.content;
                this.videoForm.sort = res.data.sort;
                this.videoForm.status = res.data.status;
                this.videoForm.video_url = res.data.video_url;
                this.videoForm.cover_url = res.data.cover_url;
                this.cover[0] = res.data.cover_url;
                this.video[0] = res.data.video_url;
            })
        },
        // 删除页
        clickDel(row){
            this.$confirm('此操作将永久删除该视频教程, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/shop_tutorial_video/delete',{id: row.id},(res)=>{
                    this.getVideoList();
                    this.$message.success('删除成功！')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 删除视频/封面
        delImageList(index){
            switch (index) {
                case 1:
                    this.cover.splice(0,1)
                    break;
                case 2:
                    this.video.splice(0,1)
                    break;
                default:
                    break;
            }
        },
        // 获取列表数据
        getVideoList(){
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                type: this.type
            }
            this.loading = true;
            common.connect('/customerservicev1/shop_tutorial_video/index',params,(res)=>{
                this.dataTable = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        // 筛选
        changeType(){
            this.getVideoList()
        },
        // 搜索
        clickSearch(){
            this.getVideoList()
        },
        // 关闭弹窗
        handleClose(){
            this.isAddShow = false;
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getVideoList()
        },
    },
}
</script>

<style lang='scss'>
.videoTutorialList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .add-video{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            .select-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .search{
                    // margin-left: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .el-input{
                        width: 200px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .operation-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont{
                font-size: 16px;
                padding: 0 10px;
                cursor: pointer;
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding: 20px;
            .el-input{
                width: 100%
            }
            .el-select{
                width: 100%;
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 150px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
